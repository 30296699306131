<template>
  <div class="extration-bdd-frais">
    <b-button
      size="sm"
      class="button-export-style mr-2"
      v-b-modal.comptabilise-modal
      title="Changer état Reglement"
    >
      <font-awesome-icon icon="clipboard-check"
    /></b-button>
    <b-modal
      ref="comptabilise-modal"
      id="comptabilise-modal"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap modal-dialog-etat"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Changer État facture</div>
        <div class="iconClose" @click.prevent="hideModal('comptabilise-modal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.stop.prevent="handleChangeState"
            class="form-modal-custom-style"
          >
            <b-form-group
              id="fieldset-horizontal-type"
              label="État Facture : "
              label-for="type-modal"
              class="text-dark"
            >
              <b-form-select
                id="fieldset-horizontal-type"
                v-model="etatModal"
                :options="ListEtat"
                required
                class="b-form-select-raduis"
              ></b-form-select>
            </b-form-group>
            <div class="form-modal-custom-style mt-2">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <span v-else>{{ error }}</span>
                </div>
              </div>
              <div class="actionModel">
                <b-button class="button-valide-style" type="submit">
                  <span>
                    Valider
                    <div v-if="loading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    selected: { required: true },
    methods: { required: true }
  },
  data() {
    return {
      etatModal: null,
      loading: false,
      error: []
    };
  },
  methods: {
    ...mapActions([, 'getTypeOfCompta']),
    async handleChangeState() {
      this.loading = true;
      let bodyFormData = new FormData();
      bodyFormData.append('comptabilise', this.etatModal);
      for (let i = 0; i < this.selected.length; i++) {
        bodyFormData.append('ids[' + i + ']', this.selected[i].id);
      }
      let payload = {
        factures: bodyFormData,
        oldFactures: this.selected
      };
      const response = await this.methods(payload);
      if (response) {
        this.loading = false;
        this.hideModal('comptabilise-modal');
        this.$emit('end', false);
      } else {
        this.loading = false;
        this.error = 'Une erreur est survenue';
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.$emit('end', false);
    },
    resetModal() {
      this.etatModal = this.ListEtat[0]?.text;
      this.loading = false;
      this.error = [];
    }
  },
  computed: {
    ...mapGetters(['typesCompta']),
    ListEtat() {
      let types = [];
      for (let i = 0; i < this.typesCompta.length; i++) {
        types.push({
          value: this.typesCompta[i],
          text: this.typesCompta[i]
        });
      }
      return types;
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  },
  mounted() {
    this.getTypeOfCompta();
    this.etatModal = this.ListEtat[0]?.text;
  }
};
</script>
