<template>
  <div class="reglement-frais">
    <div class="entete">
      <div class="box-all-filter">
        <filter-component
          v-if="checkPermission('RFTHDDF')"
          label="Date début"
          classLabel="label-box-style w-63-px"
        >
          <template v-slot:body>
            <date-picker
              v-model="date_debut"
              value-type="format"
              :disabled-date="disabledStartDate"
              @input="handleFilter"
              type="date"
              required
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker
          ></template>
        </filter-component>
        <filter-component
          label="Date fin"
          classLabel="label-box-style w-63-px"
          v-if="checkPermission('RFTHDDF')"
        >
          <template v-slot:body>
            <date-picker
              @input="handleFilter"
              v-model="date_fin"
              :disabled-date="disabledEndDate"
              value-type="format"
              type="date"
              required
              input-class="custom-date-picker-filter bg-white-color"
              class="custom-date-picker-calender-filter mt-2 mb-2"
            ></date-picker
          ></template>
        </filter-component>
        <div
          v-if="getLoaderReglementRows"
          class="chargement chargement-loading-icon m-0"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="errorReglements" class="error">
            <ul v-if="Array.isArray(errorReglements)" class="mb-0">
              <li v-for="(e, index) in errorReglements" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorReglements }}</div>
          </div>
        </div>
        <div class="box-end-position">
          <add-reglement v-if="checkPermission('RFTHNR')"></add-reglement>

          <b-button
            size="sm"
            class="button-export-style ml-2 mr-2"
            title="Filter"
            id="popover-target-1"
          >
            <font-awesome-icon icon="filter" />
          </b-button>
          <b-popover
            triggers="focus"
            target="popover-target-1"
            placement="top"
            custom-class="my-custom-class-popover-filter"
          >
            <filter-component
              label="Type Paiement"
              classLabel="w-100-px"
              v-if="checkPermission('RFTHFTR')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="type"
                  :multiple="true"
                  @input="handleFilter"
                  label="text"
                  :options="computedTypeReglement"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Type Paiement</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Types Paiement</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              label="Partenaire"
              classLabel="w-100-px
"
              v-if="checkPermission('RFTHFP')"
            >
              <template v-slot:body>
                <multiselect
                  v-model="clients"
                  :multiple="true"
                  @input="handleFilter"
                  :options="computedFilterClient"
                  label="text"
                  track-by="value"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Partenaire</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Partenaires</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              v-if="checkPermission('RFTHFTP')"
              label="Type Partenaire"
              classLabel="w-100-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="type_partenaire"
                  :multiple="true"
                  :options="computedTypePartenaire"
                  label="text"
                  track-by="value"
                  @input="handleFilter"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Type Partenaire</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Types Partenaire</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              v-if="checkPermission('RFTHFTC')"
              label="Client"
              classLabel="w-100-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="acheteur"
                  :multiple="true"
                  :options="computedFilterClient"
                  label="text"
                  track-by="value"
                  @input="handleFilter"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Client</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Clients</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              v-if="checkPermission('RFTHFTS')"
              label="Société"
              classLabel="w-100-px"
            >
              <template v-slot:body>
                <multiselect
                  v-model="vendeur"
                  :multiple="true"
                  :options="computedFilterVendeur"
                  label="text"
                  track-by="value"
                  @input="handleFilter"
                  class="customSelectMultipleFilter mt-2 mb-2"
                  :showLabels="false"
                  placeholder="sélectionner"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                >
                  <template slot="selection" slot-scope="{ values, isOpen }">
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length == 1"
                      >{{ values.length }} Société</span
                    >
                    <span
                      :class="{
                        'multiselect__single showBlock': !isOpen,
                        'multiselect__single ': isOpen
                      }"
                      v-if="values.length > 1"
                      >{{ values.length }} Sociétés</span
                    >
                  </template>
                </multiselect></template
              >
            </filter-component>
            <filter-component
              classLabel="w-100-px"
              v-if="checkPermission('RFTHFC')"
              label="Comptabilisé"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="comptabilise"
                  @input="handleFilter"
                  :options="computedComptabilisé"
                  text-field="text"
                  value-field="value"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              classLabel="w-100-px"
              v-if="checkPermission('RFTHFER')"
              label="Etat Règlement"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="etat"
                  @input="handleFilter"
                  :options="computedEtatReglement"
                  text-field="text"
                  value-field="value"
                ></b-form-select
              ></template>
            </filter-component>
            <filter-component
              classLabel="w-100-px"
              v-if="checkPermission('RFTHFEL')"
              label="Etat Lettrage"
            >
              <template v-slot:body>
                <b-form-select
                  class="
                    b-form-select-new-style b-form-select-raduis-border
                    mt-2
                    mb-2
                  "
                  v-model="lettre"
                  @input="handleFilter"
                  :options="computedEtatLettrage"
                  text-field="text"
                  value-field="value"
                ></b-form-select
              ></template>
            </filter-component>
          </b-popover>
          <comptabilise
            @end="handleResetCheck"
            :methods="changeStatutComptabilise"
            :selected="computedCheckRowsSelected"
            v-if="computedCheckRowsSelected.length > 0"
          />
          <search
            v-if="checkPermission('RFTHFCP')"
            :value="num"
            @changeSearchValue="changeSearchValue"
            :dynamic="false"
            placeHolder="Num facture / Référence / Num"
          ></search>
        </div>
      </div>
    </div>
    <!-- block filtre -->
    <div>
      <div
        v-if="
          date_debut !== null ||
            date_fin !== null ||
            num !== null ||
            comptabilise !== null ||
            etat !== null ||
            lettre !== null ||
            getShowNotifExportFile == true
        "
        class="block-filter-style"
      >
        <span class="phrase-style">Les filtres choisie sont : </span>
        <span class="item-filter-style" v-if="date_debut !== null"
          >Date début : {{ date_debut }}</span
        >
        <span class="item-filter-style" v-if="date_fin !== null"
          >Date fin : {{ date_fin }}</span
        >
        <span v-if="num !== null" class="item-filter-style"
          >Recherche : {{ num }}</span
        >
        <span v-if="comptabilise !== null" class="item-filter-style"
          >Comptabilisé : {{ comptabilise }}</span
        >
        <span v-if="etat !== null" class="item-filter-style"
          >Etat Règlement : {{ computedEtatReglementFilter(etat) }}</span
        >
        <span v-if="lettre !== null" class="item-filter-style"
          >Etat Lettrage : {{ computedEtatLettrageFilter(lettre) }}</span
        >
        <div class="notif-export-file" v-if="getShowNotifExportFile">
          Préparation du fichier en cours
        </div>
      </div>
    </div>
    <!--End block filtre -->
    <div class="chip-filters-simulation">
      <div v-if="clients && clients.length" class="block-filter">
        <span class="title-block-chip">Partenaire(s) : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="clients"
          @input="handleFilter"
        >
          <vs-chip
            :key="vd.text + 'master-filiale'"
            @click="remove(vd, 'clients', 'handleFilter')"
            v-for="vd in clients"
            closable
          >
            {{ vd.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div
        v-if="type_partenaire && type_partenaire.length"
        class="block-filter"
      >
        <span class="title-block-chip">Type(s) partenaire : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="type_partenaire"
          @input="handleFilter"
        >
          <vs-chip
            :key="p.text + 'acheteur'"
            @click="remove(p, 'type_partenaire', 'handleFilter')"
            v-for="p in type_partenaire"
            closable
          >
            {{ p.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="type && type.length" class="block-filter">
        <span class="title-block-chip">Type paiement : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="type"
          @input="handleFilter"
        >
          <vs-chip
            :key="t.text + 'type'"
            @click="remove(t, 'type', 'handleFilter')"
            v-for="t in type"
            closable
          >
            {{ t.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="acheteur && acheteur.length" class="block-filter">
        <span class="title-block-chip">Client(s) : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="acheteur"
          @input="handleFilter"
        >
          <vs-chip
            :key="p.text + 'acheteur'"
            @click="remove(p, 'acheteur', 'handleFilter')"
            v-for="p in acheteur"
            closable
          >
            {{ p.text }}
          </vs-chip>
        </vs-chips>
      </div>
      <div v-if="vendeur && vendeur.length" class="block-filter">
        <span class="title-block-chip">Société(s) : </span>
        <vs-chips
          color="rgb(145, 32, 159)"
          placeholder="New Element"
          v-model="vendeur"
          @input="handleFilter"
        >
          <vs-chip
            :key="p.text + 'acheteur'"
            @click="remove(p, 'vendeur', 'handleFilter')"
            v-for="p in vendeur"
            closable
          >
            {{ p.text }}
          </vs-chip>
        </vs-chips>
      </div>
    </div>
    <div class="body-box-rapport" :style="sizeBlockTable">
      <div class="tabs-menu-style">
        <div>
          <div class="dot-vert-big" title="Règlement lettré"></div>
          <hr class="ligne" />
          <div class="dot-red-big" title="Règlement non lettré"></div>
          <hr class="ligne" />
          <div
            class="dot-org-big"
            title="Règlement fournisseur non validées"
          ></div>
        </div>
      </div>
      <div class="table-rapport-style w-100-p">
        <b-table
          :style="sizeTable"
          show-empty
          id="my-table"
          class="custom-table-style customTableFraisSci table-header"
          :items="reglements"
          :fields="computedFields"
          bordered
          sticky-header
          hover
          responsive
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template class="check-th" v-slot:head()="data">
            <div v-if="data.field.key === 'check_all'">
              <b-form-checkbox
                class="check-th"
                :value="true"
                :unchecked-value="false"
                @change="checkAllFunction"
                v-model="checkAllReglement"
              >
              </b-form-checkbox>
            </div>
            <div
              v-else
              :class="{
                'd-flex justify-content-center align-items-center':
                  data.field.isSortable === true,
                'd-flex justify-content-center':
                  data.field.isSortable === false,
                'underline-selected-colomn': data.field.key === column
              }"
            >
              {{ data.field.label }}

              <font-awesome-icon
                v-if="data.field.isSortable === true"
                @click="listOrder(data.field.key, 'ASC')"
                icon="arrow-up"
                :class="
                  data.field.key === column && order === 'ASC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
              {{ ' ' }}
              <font-awesome-icon
                @click="listOrder(data.field.key, 'DESC')"
                v-if="data.field.isSortable === true && checkPermission('FLOC')"
                icon="arrow-down"
                :class="
                  data.field.key === column && order === 'DESC'
                    ? 'style-arrow-selected'
                    : 'style-arrow-not-selected'
                "
              />
            </div>
          </template>
          <template v-slot:cell(check_all)="data">
            <b-form-checkbox
              v-model="data.item.check"
              name="flavour-1"
              @change="checkRows(data.item)"
              class="check-th"
              :value="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
          </template>
          <template v-slot:cell(id)="data">
            <div>
              <span class="dot-vert" v-if="data.item.balance == 0"> </span
              ><span>
                <span class="dot-red" v-if="data.item.balance != 0"> </span
                ><span>
                  <span
                    class="dot-org"
                    v-if="
                      data.item.type_partenaire == 'Fournisseur' &&
                        data.item.confirmation_fournisseur == false
                    "
                  >
                  </span
                  ><span></span> </span
              ></span>
            </div>

            {{ data.item.id ? data.item.id : '-' }}
          </template>
          <template v-slot:cell(client)="data">
            {{ data.item.client ? data.item.client : '-' }}
          </template>
          <template v-slot:cell(societe)="data">
            {{ data.item.societe ? data.item.societe : '-' }}
          </template>
          <template v-slot:cell(type_partenaire)="data">
            {{ data.item.type_partenaire ? data.item.type_partenaire : '-' }}
          </template>
          <template v-slot:cell(type)="data">
            {{ data.item.type ? data.item.type : '-' }}
          </template>
          <template v-slot:cell(montant)="data">
            {{ data.item.montant | numberWithSpaces }}
            {{ data.item.currency }}
          </template>
          <template v-slot:cell(balance)="data">
            {{ data.item.balance | numberWithSpaces }}
            {{ data.item.currency }}
          </template>
          <template v-slot:cell(payment_date)="data">
            {{ data.item.payment_date ? data.item.payment_date : '-' }}
          </template>
          <template v-slot:cell(reference)="data">
            {{ data.item.reference ? data.item.reference : '-' }}
          </template>
          <template v-slot:cell(payment_note)="data">
            {{ data.item.payment_note ? data.item.payment_note : '-' }}
          </template>
          <template v-slot:cell(comptabilise)="data">
            {{ data.item.comptabilise ? data.item.comptabilise : '-' }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              v-if="checkPermission('RFTHMR')"
              class="button-update-facture-style m-1"
              size="sm"
              variant="success"
              title="Modifier un Paiement"
              @click.prevent.stop="handleUpdate(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <b-button
              v-if="checkPermission('RFTHSR')"
              v-show="data.item.factures_regles.length == 0"
              class="button-delete-facture-style m-1"
              size="sm"
              variant="danger"
              title="Supprimer un Paiement"
              @click.prevent.stop="handleDelete(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('RFTHPJ')"
              class="button-file-facture-style m-1"
              size="sm"
              variant="danger"
              title="Fichiers jointes"
              @click.prevent.stop="handleUploadFiles(data.item)"
            >
              <font-awesome-icon icon="file" />
            </b-button>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getReglementTotalRows"
            :per-page="per_page"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="per_page"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="updateReglementFacture"
      ref="updateReglementFacture"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="hideModal('updateReglementFacture')"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-reglement"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier Paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateReglementFacture')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style"
            @submit.prevent="handleSubmitUpdateReglement"
          >
            <b-row>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Type Partenaire"
                  label-for="type_partenaire"
                  class="mr-2"
                >
                  <b-form-select
                    id="type_partenaire"
                    v-model="reglementToUpdate.type_partenaire"
                    :options="computedTypePartenaire"
                    text-field="text"
                    value-field="value"
                    required
                    class="b-form-select-raduis"
                    :disabled="true"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="2.5" sm="2.5" md="2.5">
                <b-form-group
                  label="Partenaire"
                  label-for="client"
                  class="input-modal-champ mr-2"
                >
                  <b-form-input
                    id="client"
                    type="text"
                    required
                    v-model="reglementToUpdate.client"
                    :disabled="true"
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Type paiement"
                  label-for="type"
                  class="mr-2"
                >
                  <b-form-select
                    v-model="reglementToUpdate.type_id"
                    id="type"
                    :options="computedTypeReglement"
                    required
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <lcr
                v-if="
                  reglementToUpdate.echeances.length == 0 &&
                    facturesReglement.length > 0
                "
                :montantFix="true"
                :reglement="computedReg"
                :factures="facturesReglement"
                @saveDataForLcr="saveDataForLcr"
                :resetModalLcr="resetModalLcr"
              />
              <b-col
                col
                xs="2.5"
                sm="2.5"
                md="2.5"
                v-if="!computedPaiementNameSelected.includes('LCR')"
              >
                <b-form-group
                  label="Conditions du paiement"
                  label-for="condition"
                  class="mr-2"
                >
                  <b-form-select
                    v-model="reglementToUpdate.condition_id"
                    required
                    id="condition"
                    :options="computedConditionPaiement"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                col
                xs="2"
                sm="2"
                md="2"
                v-if="getConditionAmountNonLettre"
              >
                <b-form-group
                  label="Montant non alloué"
                  label-for="reste"
                  class="input-modal-champ col-2 p-0 mr-2 orange-color"
                >
                  <div class="text-dark custom-input">
                    {{ getAmountNonLettrerReglemnt | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                col
                :xs="getConditionAmountNonLettre ? '2' : '4'"
                :sm="getConditionAmountNonLettre ? '2' : '4'"
                :md="getConditionAmountNonLettre ? '2' : '4'"
              >
                <b-form-group
                  label="Montant à payer"
                  label-for="montant"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <b-form-input
                    @input="calculRestePayer"
                    v-on:keydown.13="my_method($event)"
                    id="montant"
                    type="number"
                    required
                    :disabled="true"
                    v-model="reglementToUpdate.montant"
                    min="0"
                    step="0.00001"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col col xs="1.25" sm="1.25" md="1.25">
                <b-form-group label="Unité" label-for="unite" class="mr-2">
                  <b-form-select
                    id="unite"
                    :options="computedUnite"
                    text-field="text"
                    v-model="reglementToUpdate.currency"
                    required
                    value-field="value"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="2.5" sm="2.5" md="2.5">
                <b-form-group
                  label="Réference"
                  label-for="ref"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <b-form-input
                    id="ref"
                    v-on:keydown.13="my_method($event)"
                    type="text"
                    v-model="reglementToUpdate.reference"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Date paiement"
                  label-for="date_paiement"
                  class="input-modal-champ"
                >
                  <date-picker
                    id="date_paiement"
                    value-type="format"
                    format="DD-MM-YYYY"
                    v-model="reglementToUpdate.payment_date"
                    required
                    type="date"
                  ></date-picker>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Reste"
                  label-for="reste"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <div class="text-dark custom-input">
                    {{ reglementToUpdate.balance | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Solde courant"
                  label-for="solde"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <div class="text-dark custom-input">
                    {{ reglementToUpdate.solde_init | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col col xs="2" sm="2" md="2">
                <b-form-group
                  label="Montant Echu"
                  label-for="reste"
                  class="input-modal-champ col-2 p-0 mr-2"
                >
                  <div class="text-dark custom-input">
                    {{ reglementToUpdate.solde_echeance | numberWithSpaces }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="
                reglementToUpdate &&
                  reglementToUpdate.canConfirm == true &&
                  reglementToUpdate.type_partenaire == 'Fournisseur'
              "
            >
              <b-col col xs="4" sm="4" md="4"></b-col>
              <b-col col xs="4" sm="1.25" md="1.25">
                <b-form-group
                  label="Confirmation"
                  label-for="confirmation_fournisseur"
                  class="mr-2"
                >
                  <b-form-select
                    id="confirmation_fournisseur"
                    :options="computedListConfirm"
                    text-field="text"
                    v-model="reglementToUpdate.confirmation_fournisseur"
                    required
                    value-field="value"
                    class="b-form-select-raduis"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col col xs="4" sm="4" md="4"></b-col>
            </b-row>
            <badge v-if="getConditionAmountNonLettre" />
            <b-row>
              <b-col col xs="12" sm="12" md="12">
                <b-form-group
                  label="Note"
                  label-for="note"
                  class="input-modal-champ"
                ></b-form-group>
                <b-form-textarea
                  v-on:keydown.13="my_method($event)"
                  id="note"
                  row="3"
                  v-model="reglementToUpdate.payment_note"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-tabs content-class="mt-3" fill class="mt-3 tabs-class-reglement">
              <b-tab
                :title="'Factures régler ' + reglementToUpdate.client"
                active
              >
                <div class="height-class-facture">
                  <div
                    class="box-all-filter"
                    v-if="
                      computedCheckRows.length != 0 &&
                        ((reglementToUpdate.canConfirm == true &&
                          reglementToUpdate.type_partenaire == 'Fournisseur') ||
                          reglementToUpdate.type_partenaire == 'Client') &&
                        reglementToUpdate.comptabilise != 'Comptablisé'
                    "
                  >
                    <div class="box-end-position">
                      <b-button
                        size="sm"
                        class="button-export-style"
                        title="Annuler Paiement"
                        v-b-modal.confirmAnnulation
                        v-if="reglementToUpdate.comptabilise != 'Comptablisé'"
                      >
                        <font-awesome-icon icon="wrench" class="file-medical" />
                      </b-button>
                    </div>
                  </div>

                  <div
                    v-if="show == true"
                    class="loading-custom-template-block"
                  >
                    <Half-circle-spinner
                      :animation-duration="1000"
                      :size="50"
                      :color="'#4d4bac'"
                      class="loeder"
                    />
                  </div>
                  <div class="body-box-setting">
                    <div class="table-rapport-style fix-rapport-style w-100-p">
                      <b-table
                        show-empty
                        id="my-table"
                        class="
                          custom-table-style
                          TableFraisUpdateReglementFacture
                          table-header
                        "
                        :items="reglementToUpdate.factures_regles"
                        :fields="fields"
                        bordered
                        sticky-header
                        hover
                        responsive
                        head-variant="light"
                        :tbody-tr-class="rowClass"
                        empty-text="Il n'y a aucun enregistrement à afficher"
                      >
                        <template class="check-th" v-slot:head()="data">
                          <div v-if="data.field.key === 'check_all'">
                            <b-form-checkbox
                              class="check-th"
                              :value="true"
                              :unchecked-value="false"
                              v-model="checkAll"
                              @change="selectAll"
                            >
                            </b-form-checkbox>
                          </div>
                        </template>
                        <template v-slot:cell(check_all)="data">
                          <b-form-checkbox
                            class="check-th"
                            :value="true"
                            :unchecked-value="false"
                            v-model="data.item.check"
                          >
                          </b-form-checkbox>
                        </template>
                        <template v-slot:cell(num)="data"
                          >{{ data.item.num }}
                          <font-awesome-icon
                            v-show="data.item.reglements.length"
                            :id="`popover-1-${data.item.id}`"
                            icon="info"
                            class="
                              info-icon-commercial-lot
                              content-tooltip-style
                              w-25
                            "
                          />

                          <b-popover
                            :target="`popover-1-${data.item.id}`"
                            triggers="hover"
                            custom-class="avoir-tooltip"
                          >
                            <b-table-simple class="table-fiche">
                              <b-tbody>
                                <b-tr class="title-ligne">
                                  <b-th class="newcolor">Montant reglé</b-th>
                                  <b-th class="newcolor">Date de paiement</b-th>
                                  <b-th class="newcolor">Type de paiement</b-th>
                                </b-tr>
                                <template
                                  v-for="(reglement, i) in data.item.reglements"
                                >
                                  <b-tr :key="'reglement' + i">
                                    <b-td class="newcolor"
                                      >{{ reglement.amount }} €
                                    </b-td>
                                    <b-td class="newcolor">{{
                                      formateDateGetters(reglement.payment_date)
                                    }}</b-td>
                                    <b-td class="newcolor">{{
                                      reglement.payment_type
                                    }}</b-td>
                                  </b-tr>
                                </template>
                              </b-tbody>
                            </b-table-simple>
                          </b-popover>
                        </template>
                        <template v-slot:cell(total_ttc)="data">{{
                          data.item.total_ttc | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(montant_regle)="data">{{
                          data.item.montant_regle | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(montant_a_regle)="data">
                          {{ data.item.montant_a_regle | numberWithSpaces }}
                        </template>
                        <template v-slot:cell(reste)="data">{{
                          data.item.reste | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(vendeur)="data">{{
                          data.item.vendeur
                        }}</template>
                        <template v-slot:cell(client)="data">{{
                          data.item.acheteur
                        }}</template>
                        <template v-slot:cell(delais_paiement)="data">{{
                          formateDateGetters(data.item.date_echeance)
                        }}</template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab
                :title="'Factures à régler ' + reglementToUpdate.client"
                @click="getfactures"
              >
                <div>
                  <div
                    v-if="show == true"
                    class="loading-custom-template-block"
                  >
                    <Half-circle-spinner
                      :animation-duration="1000"
                      :size="50"
                      :color="'#4d4bac'"
                      class="loeder"
                    />
                  </div>
                  <div class="body-box-setting">
                    <div class="table-rapport-style fix-rapport-style w-100-p">
                      <div class="d-flex justify-content-end mb-2">
                        <b-form-group
                          label=""
                          label-for="search-add"
                          class="input-modal-champ mb-0"
                        >
                          <b-form-input
                            autocomplete="off"
                            v-model="search"
                            placeholder="Recherche par numéro de facture"
                            class="b-form-select-raduis"
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <b-table
                        show-empty
                        id="my-table"
                        class="
                          custom-table-style
                          TableFraisUpdateReglementFacture
                          table-header
                        "
                        :items="facturesReglementComputed"
                        :fields="fields"
                        bordered
                        sticky-header
                        hover
                        responsive
                        head-variant="light"
                        :tbody-tr-class="rowClass"
                        empty-text="Il n'y a aucun enregistrement à afficher"
                      >
                        <template class="check-th" v-slot:head()="data">
                          <div v-if="data.field.key === 'check_all'">
                            <b-form-checkbox
                              class="check-th"
                              :value="true"
                              :unchecked-value="false"
                              @change="handleCheckAll"
                              v-model="checkAll"
                            >
                            </b-form-checkbox>
                          </div>
                        </template>
                        <template v-slot:cell(check_all)="data">
                          <b-form-checkbox
                            v-model="data.item.check"
                            @change="checkFacture(data.item)"
                            class="check-th"
                            :value="true"
                            :unchecked-value="false"
                          >
                          </b-form-checkbox>
                        </template>
                        <template v-slot:cell(num)="data">{{
                          data.item.num
                        }}</template>
                        <template v-slot:cell(total_ttc)="data">{{
                          data.item.total_ttc | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(montant_regle)="data">{{
                          data.item.montant_regle | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(montant_a_regle)="data">
                          <EditableInput
                            :editable="
                              reglementToUpdate.montant != null &&
                                data.item.check == true &&
                                reglementToUpdate.balance != 0
                            "
                            champName="montant_a_regle"
                            :item="data.item"
                            :value="data.item.montant_a_regle"
                            type="text"
                            editableType="input"
                            :updateFunction="updateMontantRegler"
                            :defaultColor="true"
                          />
                        </template>
                        <template v-slot:cell(reste)="data">{{
                          data.item.reste | numberWithSpaces
                        }}</template>
                        <template v-slot:cell(vendeur)="data">{{
                          data.item.vendeur
                        }}</template>
                        <template v-slot:cell(client)="data">{{
                          data.item.client
                        }}</template>
                        <template v-slot:cell(delais_paiement)="data">{{
                          formateDateGetters(data.item.date_echeance)
                        }}</template>
                      </b-table>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Fichiers jointes">
                <div class="body-box-setting height-class-facture">
                  <div class="doc-list mt-1 box-upload">
                    <div class="form-type">
                      <div class="form-groupe">
                        <div>
                          <b-form-file
                            ref="file-type"
                            v-model="files"
                            :required="false"
                            placeholder="Aucun fichier selectionné"
                            drop-placeholder="Drop file here..."
                            multiple
                          >
                          </b-form-file>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="files.length">
                  <div class="hader mb-1 mt-1">
                    <div class="titleSetting">Nouveau(x) Fichier(s)</div>
                  </div>
                  <div class="body-box-setting height-class-facture">
                    <div class="doc-list mt-1 box-upload">
                      <div class="form-type">
                        <div class="form-groupe">
                          <div
                            id="upload-file-component"
                            class="mt-3"
                            v-if="files"
                          >
                            <div
                              class="files-to-upload"
                              v-for="(file, index) in files"
                              :key="'file' + index"
                            >
                              <p class="file-name">
                                <font-awesome-icon
                                  icon="paperclip"
                                  class="file-upload-icon ml-3 mr-2"
                                />
                                <a :href="file.link" target="_blank">{{
                                  file.name
                                }}</a>
                              </p>
                              <p class="file-name">
                                <b-form-group
                                  label="Description"
                                  label-for="description"
                                  class="input-modal-champ"
                                ></b-form-group>
                                <b-form-textarea
                                  id="description"
                                  v-model="file.description"
                                ></b-form-textarea>
                              </p>
                              <p class="file-name-icon">
                                <font-awesome-icon
                                  icon="trash"
                                  class="file-trash-upload-icon mr-5"
                                  style="float: right"
                                  @click.prevent.stop="
                                    deleteFileUpload(file, index)
                                  "
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    reglementToUpdate &&
                      reglementToUpdate.files &&
                      reglementToUpdate.files.length
                  "
                >
                  <div class="hader mb-1 mt-1">
                    <div class="titleSetting">Fichier(s) Récent(s)</div>
                  </div>
                  <div class="body-box-setting height-class-facture">
                    <div class="doc-list mt-1 box-upload">
                      <div class="form-type">
                        <div class="form-groupe">
                          <div id="upload-file-component" class="mt-3">
                            <div
                              class="files-to-upload"
                              v-for="(file, index) in reglementToUpdate.files"
                              :key="'file' + index"
                            >
                              <p class="file-name">
                                <font-awesome-icon
                                  icon="paperclip"
                                  class="file-upload-icon ml-3 mr-2"
                                />
                                <a :href="file.link" target="_blank">{{
                                  file.name
                                }}</a>
                              </p>
                              <p class="file-name">
                                <b-form-group
                                  label="Description"
                                  label-for="description"
                                  class="input-modal-champ"
                                ></b-form-group>
                                <b-form-textarea
                                  id="description"
                                  v-model="file.description"
                                ></b-form-textarea>
                              </p>
                              <p class="file-name-icon">
                                <font-awesome-icon
                                  icon="trash"
                                  class="file-trash-upload-icon mr-5"
                                  style="float: right"
                                  @click.prevent.stop="deleteFile(file, index)"
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab
                :title="'Échéancier de paiement '"
                v-if="
                  computedPaiementNameSelected.includes('LCR') &&
                    reglementToUpdate.echeances
                "
              >
                <div
                  class="box-all-filter mb-2"
                  v-if="computedCheckRowsEcheans.length != 0"
                >
                  <div class="box-end-position">
                    <b-button
                      size="sm"
                      class="button-export-style"
                      title="Téléchargement PDF d'écheances"
                      @click.prevent.stop="exportMultipleEcheance"
                    >
                      <font-awesome-icon
                        icon="arrow-alt-circle-down"
                        class="file-medical"
                      />
                    </b-button>
                  </div>
                </div>
                <b-table-simple
                  class="
                    table-fiche
                    m-0
                    p-0
                    d-flex
                    justify-content-center
                    block-details-injection
                    tableInjection
                  "
                  responsive
                >
                  <b-tbody>
                    <b-tr class="title-ligne">
                      <b-th class="newcolor"
                        ><b-form-checkbox
                          v-model="checkAllEcheance"
                          name="flavour-1"
                          class="check-th"
                          :value="true"
                          @change="functionCheckAllEcheance"
                          :unchecked-value="false"
                        >
                        </b-form-checkbox
                      ></b-th>
                      <b-th class="newcolor">Libellé</b-th>
                      <b-th class="newcolor">Séquence</b-th>
                      <b-th class="newcolor">Date de création</b-th>
                      <b-th class="newcolor">Date d'échéance</b-th>
                      <b-th class="newcolor">Client/Fournisseur</b-th>
                      <b-th class="newcolor">Devise</b-th>
                      <b-th class="newcolor">Banque du tiré</b-th>
                      <b-th class="newcolor">Montant</b-th>
                      <b-th class="newcolor">Date d'envoi</b-th>
                      <b-th class="newcolor">Date de signature</b-th>
                      <b-th class="newcolor">Date valeur</b-th>
                    </b-tr>
                    <template
                      v-for="(item, index) in reglementToUpdate.echeances"
                    >
                      <b-tr :key="'eche' + index">
                        <b-td class="newcolor"
                          ><b-form-checkbox
                            v-model="item.check"
                            name="flavour-1"
                            class="check-th"
                            :value="true"
                            @change="checkRowsEcheances(item)"
                            :unchecked-value="false"
                          >
                          </b-form-checkbox
                        ></b-td>
                        <b-td class="newcolor">{{ item.libelle }}</b-td>
                        <b-td class="newcolor"
                          >{{ item.sequence }}
                          <font-awesome-icon
                            icon="arrow-alt-circle-down"
                            @click.prevent="downloadFacture(item.id)"
                            class="icon-style-color mr-1"
                            title="Télécharger la facture"
                          />
                        </b-td>
                        <b-td class="newcolor">{{ item.date_creation }}</b-td>
                        <b-td class="newcolor">
                          <date-picker
                            v-model="item.date_echeance"
                            value-type="format"
                            type="date"
                            required
                            input-class="custom-date-picker-filter"
                            class="custom-date-picker-calender-filter mt-2 mb-2"
                          ></date-picker
                        ></b-td>
                        <b-td class="newcolor">{{
                          reglementToUpdate.client
                        }}</b-td>
                        <b-td class="newcolor">{{ item.devise }}</b-td>
                        <b-td class="newcolor">{{ item.rib.rib_bank }}</b-td>
                        <b-td class="newcolor">
                          <b-form-input
                            min="0"
                            step="0.00001"
                            :max="reglementToUpdate.montant"
                            type="number"
                            autocomplete="off"
                            v-model="item.amount"
                            @input="changeMontantEcheance(index)"
                          ></b-form-input
                        ></b-td>
                        <b-td class="newcolor"
                          ><date-picker
                            v-model="item.date_envoi"
                            value-type="format"
                            type="date"
                            required
                            input-class="custom-date-picker-filter"
                            class="custom-date-picker-calender-filter mt-2 mb-2"
                          ></date-picker
                        ></b-td>
                        <b-td class="newcolor">
                          <date-picker
                            v-model="item.date_signature"
                            value-type="format"
                            type="date"
                            required
                            input-class="custom-date-picker-filter"
                            class="custom-date-picker-calender-filter mt-2 mb-2"
                          ></date-picker
                        ></b-td>
                        <b-td class="newcolor">
                          <date-picker
                            v-model="item.date_valeur"
                            value-type="format"
                            type="date"
                            required
                            input-class="custom-date-picker-filter"
                            class="custom-date-picker-calender-filter mt-2 mb-2"
                          ></date-picker
                        ></b-td>
                      </b-tr>
                    </template>
                    <b-tr v-if="reglementToUpdate.echeances.length > 0">
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor"
                        >{{ reglementToUpdate.totalEcheance }}
                      </b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                      <b-td class="newcolor">-</b-td>
                    </b-tr>
                    <b-tr v-if="reglementToUpdate.echeances.length == 0">
                      <b-td colspan="12">
                        Il n'y a aucune tranche à afficher</b-td
                      >
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tab>
            </b-tabs>
            <div class="actionModel mt-3">
              <div class="error-message ml-2 mr-2">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style mt-3"
                :disabled="reglementToUpdate.comptabilise == 'Comptablisé'"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                :disabled="reglementToUpdate.comptabilise == 'Comptablisé'"
                v-if="
                  (reglementToUpdate.canConfirm == true &&
                    reglementToUpdate.type_partenaire == 'Fournisseur') ||
                    reglementToUpdate.type_partenaire == 'Client'
                "
                type="reset"
                class="button-cancel-style mt-3 ml-3"
                v-b-modal.AnnulationTotal
              >
                <span>
                  Annuler le Paiement
                  <div v-if="loadingAnnuler" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      id="confirmAnnulation"
      ref="confirmAnnulation"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Annuler le(s) Paiement(s) sélectionnés</div>
        <div class="iconClose" @click.prevent="hideModal('confirmAnnulation')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitAnnulationReglement"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Vous devez confirmer l'annulation de ce(s) Paiement(s) ?
              </p>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div class="loading ml-2" v-if="annulerReglement">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style mt-1 ml-3"
                @click.prevent.stop="annulerConfirmation"
              >
                <span>
                  Annuler
                  <div class="loading ml-2" v-if="loadingAnnuler">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      id="deleteReglement"
      ref="deleteReglement"
      title="Suprrimer Paiement"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un Paiement</div>
        <div class="iconClose" @click.prevent="hideModal('deleteReglement')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitDeleteReglement"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer ce Paiement ?
              </p>
            </div>
            <div class="actionModel">
              <div class="error-message ml-2 mr-2">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      id="AnnulationTotal"
      ref="AnnulationTotal"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Annulation Totale de Paiement</div>
        <div class="iconClose" @click.prevent="hideModal('AnnulationTotal')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="AnnulerReglementTotal"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Vous devez confirmer l'annulation totale de ce Paiement?
              </p>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div class="loading ml-2" v-if="loadingValideAnnuler">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style mt-1 ml-3"
                @click.prevent.stop="annulerConfirmationTotal"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <b-modal
      ref="ModalPieceJoint"
      id="ModalPieceJoint"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      title="Facture"
      modal-class="cutsom-modal-bootstrap  custom-modal-upload-files"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Fichiers jointes</div>
        <div class="iconClose" @click.prevent="hideModal('ModalPieceJoint')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            class="form-modal-custom-style"
            @submit.prevent="handleSubmitFiles"
          >
            <div class="body-box-files">
              <div class="doc-list-file mt-1 box-upload">
                <div class="form-type">
                  <div class="form-groupe">
                    <div>
                      <b-form-file
                        ref="file-type"
                        v-model="files"
                        :required="false"
                        placeholder="Aucun fichier selectionné"
                        drop-placeholder="Drop file here..."
                        multiple
                      >
                      </b-form-file>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="files.length != 0">
              <div class="hader mb-1 mt-1">
                <div class="titleSetting">Nouveau(x) Fichier(s)</div>
              </div>
              <div class="body-box-files">
                <div class="doc-list-file mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon ml-3"
                            />
                            {{ file.name }}
                          </p>
                          <p class="file-name">
                            <b-form-group
                              label="Description"
                              label-for="description"
                              class="input-modal-champ"
                            ></b-form-group>
                            <b-form-textarea
                              id="description"
                              v-model="file.description"
                            ></b-form-textarea>
                          </p>
                          <p class="file-name-trash">
                            <font-awesome-icon
                              icon="trash"
                              class="file-trash-upload-icon"
                              style="float: right"
                              @click.prevent.stop="deleteFileUpload(index)"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="
                reglementToUpdate &&
                  reglementToUpdate.files &&
                  reglementToUpdate.files.length != 0
              "
            >
              <div class="hader mb-1 mt-1">
                <div class="titleSetting">Fichier(s) Récent(s)</div>
              </div>
              <div class="body-box-files">
                <div class="doc-list-file mt-1 box-upload">
                  <div class="form-type">
                    <div class="form-groupe">
                      <div id="upload-file-component">
                        <div
                          class="files-to-upload"
                          v-for="(file, index) in reglementToUpdate.files"
                          :key="'file' + index"
                        >
                          <p class="file-name">
                            <font-awesome-icon
                              icon="paperclip"
                              class="file-upload-icon ml-3"
                            />
                            <a :href="file.link" target="_blank">{{
                              file.name
                            }}</a>
                          </p>
                          <p class="file-name">
                            <b-form-group
                              label="Description"
                              label-for="description"
                              class="input-modal-champ"
                            ></b-form-group>
                            <b-form-textarea
                              id="description"
                              v-model="file.description"
                            ></b-form-textarea>
                          </p>
                          <p class="file-name-trash">
                            <font-awesome-icon
                              icon="trash"
                              class="file-trash-upload-icon"
                              style="float: right"
                              @click.prevent.stop="deleteFile(file, index)"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mr-2">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
              <b-button
                type="reset"
                class="button-cancel-style"
                @click.prevent.stop="hideModal('ModalPieceJoint')"
              >
                <span> Annuler </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>
<script>
import filterComponent from '../ThProject/frais/component/filterComponent.vue';
import moment from 'moment';
import comptabilise from '../ThProject/comptabilité/component/comptabilise.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Reglement-Facture',
  data() {
    return {
      lettre: null,
      showEcheance: false,
      search: null,
      dataLcr: null,
      reglement: null,
      resetModalLcr: false,
      checkAllEcheance: false,
      checkAllReglement: false,
      annulerReglement: false,
      loadingValideAnnuler: false,
      loadingAnnuler: false,
      loading: false,
      show: false,
      checkAll: false,
      num: null,
      reglementToDelete: null,
      clients: [],
      type_partenaire: [],
      date_debut: null,
      error: [],
      date_fin: null,
      reglementToUpdate: null,
      type: [],
      comptabilise: null,
      page: 1,
      per_page: 10,
      files: [],
      vendeur: [],
      acheteur: [],
      etat: null,
      column: 'id',
      order: 'DESC',
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
        { value: 100, text: 100 }
      ],
      fields: [
        {
          key: 'check_all',
          label: '',
          thClass: 'width-service',
          tdClass: 'width-service'
        },
        {
          key: 'num',
          label: 'Numéro'
        },
        {
          key: 'total_ttc',
          label: 'Montant Total'
        },
        {
          key: 'reste',
          label: 'Reste'
        },
        {
          key: 'montant_regle',
          label: 'Montant Réglé'
        },
        {
          key: 'montant_a_regle',
          label: 'Montant à Régler'
        },
        {
          key: 'vendeur',
          label: 'Société'
        },
        {
          key: 'client',
          label: 'Client'
        },
        {
          key: 'delais_paiement',
          label: 'Délais du paiement'
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      'getLoaderReglementRows',
      'checkPermission',
      'getShowNotifExportFile',
      'reglements',
      'typesCompta',
      'getReglementTotalRows',
      'clientsReglement',
      'soldeClient',
      'facturesReglement',
      'TypeReglement',
      'ConditionPaiement',
      'errorReglements',
      'clientFilter',
      'soldeEcheance',
      'formateDateGetters',
      'getAcheteurReglement',
      'getAmountNonLettrerReglemnt',
      'getConditionAmountNonLettre'
    ]),
    computedFilterVendeur() {
      return this.getAcheteurReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedEtatReglementFilter() {
      return function(etat) {
        if (etat == 1) {
          return 'Règlement validées';
        } else {
          return 'Règlement non validées';
        }
      };
    },
    computedEtatLettrageFilter() {
      return function(lettre) {
        if (lettre == 'lettre') {
          return 'Règlement lettré';
        } else {
          return 'Règlement non lettré';
        }
      };
    },
    computedEtatReglement() {
      return [
        {
          text: 'Tous',
          value: null
        },
        {
          text: 'Règlements validées',
          value: 1
        },
        {
          text: 'Règlements non validées',
          value: 0
        }
      ];
    },
    computedEtatLettrage() {
      return [
        {
          text: 'Tous',
          value: null
        },
        {
          text: 'Règlements lettrés',
          value: 'lettre'
        },
        {
          text: 'Règlements non lettrés',
          value: 'non_lettre'
        }
      ];
    },
    computedReg() {
      return {
        filiale_id: this.reglementToUpdate.client_id,
        type_reglement: this.reglementToUpdate.type_id,
        unite: this.reglementToUpdate.currency,
        montant_a_payer: this.reglementToUpdate.montant
      };
    },
    computedPaiementNameSelected() {
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToUpdate.type_id
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToUpdate.type_id + '';
    },
    computedPaiementNameSelectedLcr() {
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        let fund = this.TypeReglement.filter(
          i => i.id == this.reglementToUpdate.type_id
        );
        return fund && fund.length ? fund[0].name : null;
      }
      return this.reglementToUpdate.type_id + '';
    },
    computedListConfirm() {
      let confirmList = [
        {
          text: 'Oui',
          value: true
        },
        {
          text: 'Non',
          value: false
        }
      ];
      return confirmList;
    },
    facturesReglementComputed() {
      if (this.search != null && this.search != '') {
        return this.facturesReglement.filter(item =>
          item.num.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      return this.facturesReglement;
    },
    computedCheckRowsSelected() {
      let selected = [];
      this?.reglements?.map(reglement => {
        if (reglement.check == true) {
          selected.push(reglement);
        }
      });
      return selected;
    },
    computedCheckAll() {
      let table = [];
      if (
        this.facturesReglement.map(item => {
          if (item.check == true) {
            table.push(item);
          }
        })
      )
        if (table.length == this.facturesReglement.length) {
          return true;
        }
      return false;
    },
    computedCheckChipFilter() {
      if (
        this.type.length > 0 ||
        this.clients.length > 0 ||
        this.type_partenaire.length > 0 ||
        this.vendeur.length > 0 ||
        this.acheteur.length > 0
      ) {
        return true;
      }
      return false;
    },
    computedFilterClient() {
      return this.clientFilter.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedCheckRows() {
      let tableCheck = [];
      this.reglementToUpdate.factures_regles?.map(reglement => {
        if (reglement.check == true) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },
    computedCheckRowsEcheans() {
      let tableCheck = [];
      this.reglementToUpdate.echeances?.map(reglement => {
        if (reglement.check == true) {
          tableCheck.push(reglement);
        }
      });
      return tableCheck;
    },
    computedConditionPaiement() {
      return this.ConditionPaiement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedTypePartenaire() {
      let typePartenaire = [
        { text: 'Client', value: 'Client' },
        { text: 'Fournisseur', value: 'Fournisseur' }
      ];
      return typePartenaire;
    },
    computedUnite() {
      let unite = [
        { text: 'EUR', value: 'EUR' },
        { text: 'USD', value: 'USD' }
      ];
      return unite;
    },
    computedTypeReglement() {
      return this.TypeReglement.map(item => {
        return {
          text: item.name,
          value: item.id
        };
      });
    },
    computedFields() {
      let fields = [
        {
          key: 'check_all',
          label: '',
          thClass: 'th-check-obligee',
          tdClass: 'col-check-obligee',
          isSortable: false,
          show: true
        },
        {
          key: 'id',
          label: '#',
          thClass: 'th-plus-obligee',
          tdClass: 'col-plus-obligee',
          isSortable: true,
          show: this.checkPermission('RFTHACIR')
        },
        {
          key: 'client',
          label: 'PARTENAIRE',
          isSortable: true,
          show: this.checkPermission('RFTHACP')
        },
        {
          key: 'societe',
          label: 'Client',
          isSortable: true,
          show: this.checkPermission('RFTHACCL')
        },
        {
          key: 'type_partenaire',
          label: 'TYPE PARTENAIRE',
          isSortable: true,
          show: this.checkPermission('RFTHACTP')
        },
        {
          key: 'type',
          label: 'TYPE RÈGLEMENT',
          isSortable: true,
          show: this.checkPermission('RFTHACTR')
        },
        {
          key: 'montant',
          label: 'MONTANT TTC',
          isSortable: true,
          show: this.checkPermission('RFTHACMT')
        },
        {
          key: 'balance',
          label: 'BALANCE',
          isSortable: true,
          show: this.checkPermission('RFTHACB')
        },
        {
          key: 'payment_date',
          label: 'DATE DE PAIEMENT',
          isSortable: true,
          show: this.checkPermission('RFTHACDP')
        },
        {
          key: 'reference',
          label: 'RÉFERENCE',
          isSortable: true,
          show: this.checkPermission('RFTHACR')
        },
        {
          key: 'payment_note',
          label: 'NOTE',
          isSortable: true,
          show: this.checkPermission('RFTHACN')
        },
        {
          key: 'comptabilise',
          label: 'COMPTABILISÉ',
          isSortable: true,
          show: this.checkPermission('RFTHACC')
        },
        {
          key: 'actions',
          label: 'Actions',
          isSortable: false,
          show: true
        }
      ];
      return fields.filter(item => item.show == true);
    },
    computedComptabilisé() {
      let types = [];
      types.push({
        value: null,
        text: 'Tous'
      });
      for (let i = 0; i < this.typesCompta.length; i++) {
        types.push({
          value: this.typesCompta[i],
          text: this.typesCompta[i]
        });
      }

      return types;
    },
    sizeBlockTable() {
      let heigthBlock = 0;

      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        if (table.length) {
          for (let i = 0; i < table.length; i++) {
            table2.push(table[i].clientHeight);
          }
        }

        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 42
          : 0;
      }
      if (this.computedCheckFilterSimple == true) {
        heigthBlock = heigthBlock + 42;
      }
      let hei = 129 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    sizeTable() {
      let heigthBlock = 0;
      if (this.computedCheckChipFilter == true) {
        let table2 = [];
        let table = document.getElementsByClassName('con-chips');
        table.forEach(element => {
          table2.push(element.clientHeight);
        });
        heigthBlock = table2.length
          ? Math.max(...table2)
          : this.computedCheckChipFilter == true
          ? 42
          : 0;
      }
      if (this.computedCheckFilterSimple == true) {
        heigthBlock = heigthBlock + 42;
      }
      // calc(100vh - 228px)
      let hei = 217 + heigthBlock;
      return (
        'max-height : calc(100vh - ' +
        `${hei}` +
        'px)!important ; height: calc(100vh - ' +
        `${hei}` +
        'px)!important;'
      );
    },
    computedCheckFilterSimple() {
      if (
        this.date_debut != null ||
        this.date_fin != null ||
        this.num != null ||
        this.comptabilise != null
      ) {
        return true;
      }
      return false;
    }
  },
  components: {
    addReglement: () => import('./component/addReglement.vue'),
    Card: () => import('@/views/component/card.vue'),
    EditableInput: () => import('@/views/component/EditableInput.vue'),
    filterComponent,
    search: () => import('@/views/component/SearchInputComponent.vue'),
    comptabilise,
    lcr: () => import('@/views/component/reglement/lcr.vue'),
    badge: () => import('@/views/component/badge.vue')
  },
  methods: {
    ...mapActions([
      'getReglements',
      'getAllTypesReglement',
      'getTypeOfCompta',
      'getAllConditionsPaiement',
      'getFactureByFilialeReglement',
      'getSoldeClient',
      'getAmountNonLettrer',
      'resetMutationsReglements',
      'annulationPaymentFacture',
      'annulationPayment',
      'updateReglement',
      'deleteAttachementReglement',
      'AttachFiles',
      'getAllClientsReglement',
      'deleteReglement',
      'getSoldeEcheance',
      'updateFileReglement',
      'updatePaymentEcheance',
      'changeStatutComptabilise',
      'exportEcheanceReglement',
      'createReglementLcrFactureEcheances',
      'getAcheteurReglements'
    ]),
    async listOrder(col, ord) {
      this.column = col;
      this.order = ord;
      await this.getReglements({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        num: this.num,
        type: this.type,
        clients: this.clients,
        type_partenaire: this.type_partenaire,
        comptabilise: this.comptabilise,
        page: this.page,
        order: this.order,
        column: this.column,
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        etat: this.etat,
        per_page: this.per_page,
        etat_lettrage: this.lettre
      });
    },
    saveDataForLcr(data) {
      this.dataLcr = data;
    },
    async exportMultipleEcheance() {
      let bodyFormData = new FormData();
      let checked = [];
      checked = this.reglementToUpdate.echeances.filter(
        item => item.check == true
      );
      checked.forEach((element, index) => {
        bodyFormData.append('ids[' + index + ']', element.id);
      });
      const response = await this.exportEcheanceReglement(bodyFormData);
      if (response) {
        this.reglementToUpdate.echeances.forEach((element, index) => {
          element.check = false;
        });
        this.checkAllEcheance = false;
      }
    },
    functionCheckAllEcheance() {
      this.reglementToUpdate.echeances.map(item => {
        item.check = this.checkAllEcheance;
      });
    },
    checkRowsEcheances(item) {
      let checked = [];
      if (item.check == false && this.checkAllEcheance == true) {
        this.checkAllEcheance = false;
      }
      this.reglementToUpdate?.echeances?.map(facture => {
        if (facture.check == true) {
          checked.push(facture);
        }
      });
      if (checked.length == this.reglementToUpdate.echeances.length) {
        this.checkAllEcheance = true;
      }
    },
    async downloadFacture(id) {
      let bodyFormData = new FormData();
      bodyFormData.append('ids[0]', id);
      await this.exportEcheanceReglement(bodyFormData);
    },
    handleResetCheck(value) {
      if (value == false) {
        this.checkAllReglement = false;
        this?.reglements?.map(reglement => (reglement.check = false));
      }
    },
    checkRows(item) {
      let checked = [];
      if (item.check == false && this.checkAllReglement == true) {
        this.checkAllReglement = false;
      }
      this?.reglements?.map(reglement => {
        if (reglement.check == true) {
          checked.push(reglement);
        }
      });
      if (checked.length == this.reglements.length) {
        this.checkAllReglement = true;
      }
    },
    checkAllFunction() {
      this.reglements.map(facture => (facture.check = this.checkAllReglement));
    },
    changeMontantEcheance(index) {
      let sum = 0;
      let index_update =
        index == this.reglementToUpdate.echeances.length - 1
          ? 0
          : this.reglementToUpdate.echeances.length - 1;

      let index_start =
        index == this.reglementToUpdate.echeances.length - 1 ? 1 : 0;
      let index_end =
        index == this.reglementToUpdate.echeances.length - 1
          ? this.reglementToUpdate.echeances.length
          : this.reglementToUpdate.echeances.length - 1;
      this.reglementToUpdate.echeances.forEach((element, index) => {
        if (index >= index_start && index < index_end) {
          sum = parseFloat(sum) + parseFloat(element.amount);
        }
      });
      this.reglementToUpdate.echeances[index_update].amount = (
        this.reglementToUpdate.totalEcheance - sum
      ).toFixed(2);
    },
    setLocalStorageReglementFactures() {
      localStorage.setItem(
        'reglement-factures-cdg-th',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          clients: this.clients,
          type_partenaire: this.type_partenaire,
          type: this.type,
          per_page: this.perPage,
          page: this.page,
          num: this.num,
          comptabilise: this.comptabilise,
          vendeur: this.vendeur,
          acheteur: this.acheteur,
          etat: this.etat,
          column: this.column,
          order: this.order
        })
      );
    },
    changeSearchValue(event) {
      this.num = event;
      this.handleFilter();
    },
    async handleSubmitFiles() {
      this.error = [];
      if (this.files.length != 0 || this.reglementToUpdate.files.length != 0) {
        this.loading = true;
        if (this.reglementToUpdate?.files?.length != 0) {
          let bodyFormData = new FormData();
          for (let i = 0; i < this.reglementToUpdate.files?.length; i++) {
            bodyFormData.append(
              'files[' + i + '][file_id]',
              this.reglementToUpdate.files[i].id
            );
            bodyFormData.append(
              'files[' + i + '][description]',
              this.reglementToUpdate.files[i].description
            );
          }
          const response = await this.updateFileReglement({
            bodyFormData: bodyFormData,
            reglement: this.reglementToUpdate
          });
          if (response.success) {
            if (this.files.length != 0) {
              let bodyFormData = new FormData();
              bodyFormData.append('payment_id', this.reglementToUpdate.id);
              for (let i = 0; i < this.files?.length; i++) {
                bodyFormData.append('files[' + i + '][file]', this.files[i]);
                if (this.files[i]['description'] != undefined) {
                  bodyFormData.append(
                    'files_descriptions[' + i + ']',
                    this.files[i]['description']
                  );
                }
              }
              const responseAttach = await this.AttachFiles({
                bodyFormData: bodyFormData,
                payment_id: this.reglementToUpdate.id
              });
              if (responseAttach.success) {
                this.loading = false;
                this.files = [];
                return true;
              }
            } else {
              this.files = [];
              this.loading = false;
              return true;
            }
          }
        } else {
          if (this.files.length != 0) {
            let bodyFormData = new FormData();
            bodyFormData.append('payment_id', this.reglementToUpdate.id);
            for (let i = 0; i < this.files?.length; i++) {
              bodyFormData.append('files[' + i + '][file]', this.files[i]);
              if (this.files[i]['description'] != undefined) {
                bodyFormData.append(
                  'files_descriptions[' + i + ']',
                  this.files[i]['description']
                );
              }
            }
            const responseAttachFiles = await this.AttachFiles({
              bodyFormData: bodyFormData,
              payment_id: this.reglementToUpdate.id
            });

            if (responseAttachFiles.success) {
              this.loading = false;
              return true;
            }
          }
        }
      }
    },
    deleteFileUpload(index) {
      this.files.splice(index, 1);
    },
    handleUploadFiles(item) {
      this.reglementToUpdate = item;
      this.$refs['ModalPieceJoint'].show();
    },
    remove(item, filter, fct) {
      this[filter].splice(this[filter].indexOf(item), 1);
      this[fct]();
    },
    async submitDeleteReglement() {
      this.loading = true;
      const response = await this.deleteReglement(this.reglementToDelete.id);
      if (response.success) {
        this.loading = false;
        this.hideModal('deleteReglement');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    handleDelete(item) {
      this.reglementToDelete = { ...item };
      this.$refs['deleteReglement'].show();
    },
    async deleteFile(item, index) {
      const response = await this.deleteAttachementReglement(item.id);
      if (response) {
        this.reglementToUpdate.files.splice(index, 1);
      }
    },
    calculRestePayer() {
      if (
        parseFloat(this.reglementToUpdate.montant) >
        this.reglementToUpdate.montant_initial
      ) {
        let montant =
          parseFloat(this.reglementToUpdate.montant) -
          this.reglementToUpdate.montant_initial;
        this.reglementToUpdate.balance =
          parseFloat(this.reglementToUpdate.balance) + montant;
        let TableF = this.facturesReglement?.filter(
          item => item.check == false
        );
        if (TableF.length != this.facturesReglement.length) {
          this.getFacturesFiliales();
        }
      }
    },
    async getFacturesFiliales() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (response) {
        this.getAmountNonLettrer({
          filiale_id: this.reglementToAdd.filiale_id,
          type_partenaire: this.reglementToAdd.type_partenaire
        });
        const responseSolde = await this.getSoldeClient({
          id: this.reglementToAdd.filiale_id,
          type_partenaire: this.reglementToAdd.type_partenaire
        });
        if (responseSolde) {
          this.reglementToUpdate.solde = this.soldeClient;
          this.reglementToUpdate.solde_init = this.soldeClient;
          const responseEcheance = await this.getSoldeEcheance({
            id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          if (responseEcheance) {
            this.reglementToUpdate.solde_echeance = this.soldeEcheance;
            this.show = false;
          }
        }
      }
    },
    async handleSubmitUpdateReglement() {
      if (this.reglementToUpdate.montant == 0) {
        this.error = 'Il faut avoir un montant à payer supèrieur à 0 ';
      } else {
        this.loading = true;
        this.reglementToUpdate.echeances.forEach(async (element, index) => {
          let bodyFormDataEcheance = new FormData();
          bodyFormDataEcheance.append('id', element.id);
          bodyFormDataEcheance.append('libelle', element.libelle);
          bodyFormDataEcheance.append('client_id', element.client_id);
          bodyFormDataEcheance.append('date_creation', element.date_creation);
          bodyFormDataEcheance.append('date_echeance', element.date_echeance);
          bodyFormDataEcheance.append(
            'beneficiaire_id',
            element.beneficiaire_id
          );
          bodyFormDataEcheance.append('valeur_en', element.valeur_en);
          bodyFormDataEcheance.append('date_envoi', element.date_envoi);
          bodyFormDataEcheance.append('date_signature', element.date_signature);
          bodyFormDataEcheance.append('date_valeur', element.date_valeur);
          bodyFormDataEcheance.append('devise', element.devise);
          bodyFormDataEcheance.append('amount', element.amount);
          bodyFormDataEcheance.append('rib_id', element.rib.id);
          bodyFormDataEcheance.append('sequence', element.sequence);
          bodyFormDataEcheance.append('etat', element.etat);
          bodyFormDataEcheance.append('ordre_de', element.ordre_de);
          const response = await this.updatePaymentEcheance(
            bodyFormDataEcheance
          );
          if (response.succes) {
            this.reglementToUpdate.echeances[index].amount =
              response.data.amount;
            this.reglementToUpdate.echeances[index].date_creation =
              response.data.date_creation;
            this.reglementToUpdate.echeances[index].date_echeance =
              response.data.date_echeance;
            this.reglementToUpdate.echeances[index].date_signature =
              response.data.date_signature;
            this.reglementToUpdate.echeances[index].date_valeur =
              response.data.date_valeur;
            this.reglementToUpdate.echeances[index].date_envoi =
              response.data.date_envoi;
          }
        });
        let bodyFormData = new FormData();
        bodyFormData.append('payment_id', this.reglementToUpdate.id);
        bodyFormData.append('balance', this.reglementToUpdate.balance);
        bodyFormData.append('amount', this.reglementToUpdate.montant);
        bodyFormData.append('currency', this.reglementToUpdate.currency);
        bodyFormData.append('canConfirm', this.reglementToUpdate.canConfirm);
        bodyFormData.append(
          'confirmation_fournisseur',
          this.reglementToUpdate.confirmation_fournisseur
        );
        bodyFormData.append(
          'payment_date',
          this.reglementToUpdate.payment_date
        );
        bodyFormData.append('type_id', this.reglementToUpdate.type_id);
        if (!this.computedPaiementNameSelected.includes('LCR')) {
          bodyFormData.append(
            'payment_condition_id',
            this.reglementToUpdate.condition_id
          );
        }
        bodyFormData.append('filiale_id', this.reglementToUpdate.client_id);
        bodyFormData.append(
          'payment_note',
          this.reglementToUpdate.payment_note
        );
        bodyFormData.append('reference', this.reglementToUpdate.reference);
        bodyFormData.append(
          'type_partenaire',
          this.reglementToUpdate.type_partenaire
        );

        for (let i = 0; i < this.facturesReglement.length; i++) {
          if (this.facturesReglement[i].check == true) {
            bodyFormData.append(
              'factures[' + i + '][id]',
              this.facturesReglement[i].id
            );
            bodyFormData.append(
              'factures[' + i + '][montant_regle]',
              this.facturesReglement[i].montant_a_regle
            );
            bodyFormData.append(
              'factures[' + i + '][total_ttc]',
              this.facturesReglement[i].total_ttc
            );
          }
        }
        const responseUpdate = await this.updateReglement({
          bodyFormData: bodyFormData,
          oldReglement: this.reglementToUpdate
        });
        if (responseUpdate.succes) {
          if (
            this.dataLcr &&
            this.dataLcr?.lcr &&
            this.dataLcr?.lcr?.length != 0 &&
            this.reglementToUpdate.type_id != null &&
            this.computedPaiementNameSelectedLcr.includes('LCR') &&
            this.reglementToUpdate.echeances.length == 0
          ) {
            let bodyFormDataEch = new FormData();
            this.dataLcr?.lcr?.forEach((element, i) => {
              bodyFormDataEch.append(
                'echeances[' + i + '][libelle]',
                element.libelle
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][client_id]',
                this.dataLcr.client_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][date_echeance]',
                element.date
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][beneficiaire_id]',
                this.dataLcr.beneficiaire_id
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][valeur_en]',
                this.dataLcr.valeur_en
              );
              bodyFormDataEch.append('echeances[' + i + '][date_envoi]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][date_signature]',
                null
              );
              bodyFormDataEch.append('echeances[' + i + '][date_valeur]', null);
              bodyFormDataEch.append(
                'echeances[' + i + '][devise]',
                this.dataLcr.devise
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][amount]',
                element.value
              );
              bodyFormDataEch.append(
                'echeances[' + i + '][rib_id]',
                this.dataLcr.rib.id
              );
            });
            const resEch = await this.createReglementLcrFactureEcheances({
              response: this.reglementToUpdate,
              data: bodyFormDataEch
            });
            if (resEch) {
              let total = 0;
              this.reglementToUpdate.echeances.map(item => {
                total = total + item.amount;
              });
              this.reglementToUpdate.totalEcheance = total.toFixed(2);
            }
          }
          const responseFile = await this.handleSubmitFiles();
          if (responseFile) {
            this.files = [];
          }
          this.loading = false;
          this.show = true;
          this.getAmountNonLettrer({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          const responseFacture = await this.getFactureByFilialeReglement({
            filiale_id: this.reglementToUpdate.client_id,
            type_partenaire: this.reglementToUpdate.type_partenaire
          });
          if (responseFacture) {
            this.clientsReglement.map(item => {
              if (item.id == this.reglementToUpdate.client_id) {
                this.vendeur = item.name;
              }
            });
            this.getAmountNonLettrer({
              filiale_id: this.reglementToUpdate.client_id,
              type_partenaire: this.reglementToUpdate.type_partenaire
            });
            const responsegetSoldeClient = await this.getSoldeClient({
              id: this.reglementToUpdate.client_id,
              type_partenaire: this.reglementToUpdate.type_partenaire
            });
            if (responsegetSoldeClient) {
              this.reglementToUpdate.solde = this.soldeClient;
              this.reglementToUpdate.solde_init = this.soldeClient;
              const responseEcheance = await this.getSoldeEcheance({
                id: this.reglementToUpdate.client_id,
                type_partenaire: this.reglementToUpdate.type_partenaire
              });
              if (responseEcheance) {
                this.reglementToUpdate.solde_echeance = this.soldeEcheance;
                this.show = false;
              }
              // if (response) {
              //   this.reglementToUpdate.solde_echeance = this.soldeEcheance;
              //   this.show = false;
              // }
            }
          }
        } else {
          this.loading = false;
        }
      }
    },
    checkFacture(item) {
      let dataCheck = this.facturesReglement.filter(
        i => i.check == true && i.id != item.id
      );
      if (
        dataCheck.length == 0 ||
        (dataCheck.length > 0 && dataCheck[0].vendeur_id == item.vendeur_id)
      ) {
        this.reglementToUpdate.balance = parseFloat(
          this.reglementToUpdate.balance
        );
        this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);
        if (this.reglementToUpdate.balance > 0) {
          if (item.check == true) {
            if (this.reglementToUpdate.balance > item.resteInitial) {
              this.reglementToUpdate.balance =
                this.reglementToUpdate.balance - parseFloat(item.resteInitial);
              this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
                2
              );
              item.reste = 0;
              item.montant_a_regle =
                parseFloat(item.montant_a_regle) +
                parseFloat(item.resteInitial);
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            } else {
              item.montant_a_regle = this.reglementToUpdate.balance.toFixed(2);
              item.reste =
                parseFloat(item.resteInitial) - this.reglementToUpdate.balance;
              item.reste = item.reste.toFixed(2);
              this.reglementToUpdate.balance = 0;
              this.reglementToUpdate.solde =
                this.reglementToUpdate.solde - parseFloat(item.montant_a_regle);
              this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
                2
              );
              this.reglementToUpdate.solde = Math.abs(
                this.reglementToUpdate.solde
              );
              if (this.computedCheckAll == true) {
                this.checkAll = true;
              } else {
                this.checkAll = false;
              }
            }
          } else {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            if (this.computedCheckAll == true) {
              this.checkAll = true;
            } else {
              this.checkAll = false;
            }
          }
        } else {
          if (item.check == false) {
            this.reglementToUpdate.balance =
              this.reglementToUpdate.balance + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
              2
            );
            this.reglementToUpdate.solde =
              this.reglementToUpdate.solde + parseFloat(item.montant_a_regle);
            this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(
              2
            );
            item.reste = parseFloat(item.resteInitial);
            item.montant_a_regle = parseFloat(item.montant_a_regle_initial);
            this.checkAll = false;
          } else {
            item.check = false;
            this.checkAll = false;
          }
        }
      } else {
        item.check = !item.check;
      }
    },
    handleCheckAll() {
      if (this.checkAll == true) {
        let table = this.facturesReglementComputed.filter(
          item => item.check == false
        );
        table.map(item => {
          if (this.reglementToUpdate.balance > 0) {
            item.check = true;
            this.checkFacture(item);
          }
        });
      } else {
        let table = this.facturesReglementComputed.filter(
          item => item.check == true
        );
        table.map(item => {
          if (item.check == true) {
            item.check = false;
            this.checkFacture(item);
          }
        });
      }
    },
    annulerConfirmationTotal() {
      this.$refs['AnnulationTotal'].hide();
    },
    async AnnulerReglementTotal() {
      this.loadingValideAnnuler = true;
      const response = await this.annulationPayment({
        id: this.reglementToUpdate.id,
        oldReglement: this.reglementToUpdate
      });
      if (response) {
        this.loadingValideAnnuler = false;
        this.$refs['AnnulationTotal'].hide();
        this.hideModal('updateReglementFacture');
      }
    },
    async submitAnnulationReglement() {
      this.annulerReglement = true;
      let bodyFormData = new FormData();
      let indexAnnulationReglement = 0;
      for (let j = 0; j < this.reglementToUpdate.factures_regles.length; j++) {
        if (this.reglementToUpdate.factures_regles[j].check == true) {
          for (
            let i = 0;
            i < this.reglementToUpdate.factures_regles[j].reglements.length;
            i++
          ) {
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].facture_id
            );
            bodyFormData.append(
              'obj[' + indexAnnulationReglement + '][payment_facture_id]',
              this.reglementToUpdate.factures_regles[j].reglements[i].id
            );
            indexAnnulationReglement = indexAnnulationReglement + 1;
          }
        }
      }
      const response = await this.annulationPaymentFacture({
        bodyFormData: bodyFormData,
        oldReglement: this.reglementToUpdate
      });
      if (response.succes) {
        this.annulerReglement = false;
        this.hideModal('confirmAnnulation');
      }
    },
    selectAll() {
      if (this.reglementToUpdate.balance != 0) {
        if (this.checkAll == true) {
          this.facturesReglement.map(item => {
            if (this.reglementToUpdate.balance > 0) {
              item.check = true;
              this.checkFacture(item);
            }
          });
        } else {
          this.facturesReglement.map(item => {
            if (item.check == true) {
              item.check = false;
              this.checkFacture(item);
            }
          });
        }
      } else {
        this.checkAll = false;
      }
    },
    annulerConfirmation() {
      this.hideModal('confirmAnnulation');
      this.reglementToUpdate.factures_regles.map(reglement => {
        if (reglement.check == true) {
          reglement.check = false;
        }
      });
    },
    resetModal() {
      this.loading = false;
      this.show = false;
      this.checkAll = false;
      this.reglementToDelete = {};
      this.error = [];
    },
    async getfactures() {
      this.show = true;
      const response = await this.getFactureByFilialeReglement({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (response) {
        this.reglementToUpdate.balance = this.reglementToUpdate.balance_initial;
        this.show = false;
      }
    },
    my_method(event) {
      if (event) event.preventDefault();
      if (event) event.stopPropagation();
    },

    rowClass(item, type) {
      if (item && item.reste == 0) {
        return 'ligneAlert';
      }
      return 'ligneNormale';
    },
    updateMontantRegler(payload) {
      this.reglementToUpdate.balance = parseFloat(
        this.reglementToUpdate.balance
      );
      this.reglementToUpdate.solde = parseFloat(this.reglementToUpdate.solde);

      if (
        parseFloat(payload.row.montant_a_regle) +
          parseFloat(this.reglementToUpdate.balance) >
        parseFloat(payload.value)
      ) {
        payload.row.montant_a_regle = parseFloat(payload.value).toFixed(2);
        payload.row.reste = parseFloat(
          payload.row.resteInitial - payload.value.toFixed(2)
        );
        payload.row.reste = payload.row.reste.toFixed(2);
        payload.row.reste = parseFloat(payload.row.reste);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.balance =
          this.reglementToUpdate.balance - parseFloat(payload.value);
        this.reglementToUpdate.balance = this.reglementToUpdate.balance.toFixed(
          2
        );
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde + parseFloat(payload.row.resteInitial);
        this.reglementToUpdate.solde =
          this.reglementToUpdate.solde - parseFloat(payload.value);
        this.reglementToUpdate.solde = this.reglementToUpdate.solde.toFixed(2);
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleUpdate(item) {
      this.$refs['updateReglementFacture'].show();
      this.show = true;
      let total = 0;
      this.reglementToUpdate = item;
      this.reglementToUpdate.echeances.map(item => {
        total = total + item.amount;
      });
      if (this.reglementToUpdate && this.reglementToUpdate.type_id != null) {
        this.computedTypeReglement.map(item => {
          if (
            item.value == this.reglementToUpdate.type_id &&
            item.text == 'LCR Lettre de change Relevé'
          ) {
            this.showEcheance = true;
          }
        });
      }
      this.reglementToUpdate.totalEcheance = total.toFixed(2);
      this.reglement = {
        filiale_id: item.client_id,
        type_reglement: item.type_id,
        unite: item.currency,
        montant_a_payer: item.montant
      };
      this.getAmountNonLettrer({
        filiale_id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      const responsegetSoldeClient = await this.getSoldeClient({
        id: this.reglementToUpdate.client_id,
        type_partenaire: this.reglementToUpdate.type_partenaire
      });
      if (responsegetSoldeClient) {
        this.reglementToUpdate.solde = this.soldeClient;
        this.reglementToUpdate.solde_init = this.soldeClient;
        const responsegetSoldeEcheance = await this.getSoldeEcheance({
          id: this.reglementToUpdate.client_id,
          type_partenaire: this.reglementToUpdate.type_partenaire
        });
        if (responsegetSoldeEcheance) {
          this.reglementToUpdate.solde_echeance = this.soldeEcheance;
        }
      }
      this.show = false;
    },
    handleFilter() {
      this.setLocalStorageReglementFactures();
      this.getReglements({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        num: this.num,
        type: this.type,
        clients: this.clients,
        type_partenaire: this.type_partenaire,
        comptabilise: this.comptabilise,
        page: this.page,
        order: this.order,
        column: this.column,
        vendeur: this.vendeur,
        acheteur: this.acheteur,
        etat: this.etat,
        per_page: this.per_page,
        etat_lettrage: this.lettre
      });
    },

    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    changePerPage() {
      this.page = 1;
      this.handleFilter();
    }
  },
  filters: {
    formateValue: value => {
      if (value && value != null && value != '') {
        return parseFloat(value)?.toFixed(2);
      }
      return value;
    },
    numberWithSpaces: x => {
      // return new Intl.NumberFormat().format(x);
      if (x != null && x != undefined) {
        if (Math.sign(x) === -1) {
          var y = Math.abs(x);
          var parts = y.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return '-' + parts.join('.');
        } else {
          var parts = x.toString().split('.');
          parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
          return parts.join('.');
        }
      }
    }
  },
  async mounted() {
    if (localStorage.getItem('reglement-factures-cdg-th')) {
      this.date_debut = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).date_debut;
      this.date_fin = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).date_fin;
      this.type = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).type;
      this.clients = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).clients;
      this.type_partenaire = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).type_partenaire;
      this.num = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).num;
      this.comptabilise = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).comptabilise;
      this.vendeur = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).vendeur;
      this.acheteur = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).acheteur;
      this.etat = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).etat;
      this.column = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).column;
      this.order = JSON.parse(
        localStorage.getItem('reglement-factures-cdg-th')
      ).order;
    } else {
      this.setLocalStorageReglementFactures();
    }
    this.getAcheteurReglements();
    this.getAllClientsReglement();
    this.getTypeOfCompta();
    this.getAllTypesReglement();
    this.getAllConditionsPaiement();
    this.handleFilter();
  }
};
</script>

<style lang="scss">
.style-arrow-not-selected {
  width: 14px;
  height: 14px;
  color: #aaacb0;
  margin-left: 1px;
  margin-right: 1px;
}
.style-arrow-selected {
  width: 14px;
  height: 14px;
  color: #4d4bac;
  margin-left: 1px;
  margin-right: 1px;
}
.TableFraisUpdateReglementFacture {
  max-height: calc(100vh - 545px);
  height: calc(100vh - 545px);
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Parcourir...';
  background-color: #4d4bac;
  color: white;
  height: 37px;
}

.custom-file-input:lang(en) ~ .custom-file-label {
  border: none;
  border-radius: 20px;
  background-color: #fff;

  span {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-weight: 500;
    pointer-events: none;
    margin-top: 5px;
  }
}
#upload-file-component {
  overflow-y: auto;
  max-height: calc(100vh - 274px) !important;
  position: relative;
}
.doc-list-file {
  width: 100%;
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;
    }
  }
}
.form-type {
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 56%;
  margin: auto;

  .form-groupe {
    .file-header-titles {
      align-items: center;
      padding: 4px 20px;
      display: flex;

      .file-name-title,
      .file-category-title {
        text-align: center;
        color: #495057;
        font-weight: 600;
        border-radius: 6px;
        // background-color: #8d8cb7;
        padding: 3px;
      }

      .file-name-title {
        width: 80%;
        margin-right: 10px;
      }

      .file-category-title {
        width: 40%;
      }
    }

    .files-to-upload {
      display: flex;
      align-items: center;
      padding: 2px 20px;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }
      .file-name-trash {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 20%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }

      .file-category {
        width: 40%;
        font-size: 13px;
        border-radius: 17px;
      }
    }

    .actionFile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;

      .button-cancel-style {
        background-color: #49505782;
        color: white;
        border: none;
      }

      .chargement {
        margin-left: 5px;

        .spinner-border {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
<style lang="scss" scoped>
.tabs-menu-style {
  width: 37px;
}
.body-box-files {
  display: flex;
  background-color: #f5f4fa;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.button-update-facture-style {
  background-color: #4cb07e;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border-radius: 25px;
  border: none;
}
.button-delete-facture-style {
  background-color: rgb(185, 34, 34);
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border-radius: 25px;
  border: none;
}


.button-file-facture-style {
  background-color: rgb(153, 140, 189);
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border-radius: 25px;
  border: none;
}
.body-box-rapport {
  display: flex;
  background-color: #f5f4fa;
  height: calc(100vh - 165px);
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}
.reglement-frais {
  padding-top: 20px;
  padding-left: 15px;
  width: 99%;
  .entete {
    padding: 5px 10px;
    width: 99%;
  }
}
.button-update-facture-style {
  background-color: #4cb07e;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border-radius: 25px;
  border: none;
}
.button-delete-facture-style {
  background-color: rgb(185, 34, 34);
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border-radius: 25px;
  border: none;
}
.body-box-setting {
  display: flex;
  background-color: #f5f4fa;
  height: auto;
  margin-top: 3px;
  border-radius: 28px;
  position: relative;
  padding: 11px;
}

* {
  font-family: 'Montserrat', sans-serif;
}
.body-box-rapport {
  height: calc(100vh - 68px);
}
.height-class-facture {
  .body-box-rapport {
    height: calc(100vh - 264px) !important;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #7978aa;
  border-radius: 7px;
}
.style-block--filter {
  height: 100%;
}

.underline-selected-colomn {
  text-decoration: underline;
}
</style>
<style lang="scss">
.form-type {
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 56%;
  margin: auto;

  .form-groupe {
    .file-header-titles {
      align-items: center;
      padding: 4px 20px;
      display: flex;

      .file-name-title,
      .file-category-title {
        text-align: center;
        color: #495057;
        font-weight: 600;
        border-radius: 6px;
        padding: 3px;
      }

      .file-name-title {
        width: 60%;
        margin-right: 10px;
      }

      .file-category-title {
        width: 40%;
      }
    }

    .files-to-upload {
      display: flex;
      align-items: center;
      padding: 2px 20px;

      .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 70%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-upload-icon {
          color: #2dabe2;
        }
        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }
      .file-name-icon {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 20%;
        margin-right: 10px;
        text-align: left;
        font-size: 1rem;
        color: black;

        .file-trash-upload-icon {
          color: red;
          cursor: pointer;
        }
      }

      .file-category {
        width: 40%;
        font-size: 13px;
        border-radius: 17px;
      }
    }

    .actionFile {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;

      .button-cancel-style {
        background-color: #49505782;
        color: white;
        border: none;
      }

      .chargement {
        margin-left: 5px;

        .spinner-border {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .error {
      color: red;
      font-size: 14px;
      text-align: center;
    }
  }
}
.doc-list {
  width: 100%;
  .files-tabs {
    .nav-tabs {
      justify-content: center;
      background-color: #fff;
      padding: 5px 0;
      border: none;
      border-radius: 49px;
      width: 57%;
      margin: auto;
      margin-bottom: 15px;

      .files-tab-title {
        position: relative;
        font-size: 12px;
        color: #404346;
        background-color: transparent;
        border: 1px solid #d5d5d5;
        border-radius: 20px;
        padding: 0px 10px;
        padding: 2px 11px;
        cursor: pointer;
        user-select: none;
        transition: all 0.4s ease;
        border: none;
        margin-right: 8px;

        .counter {
          color: #858585;
          margin-left: 5px;
        }

        &.active {
          color: white !important;
          border-radius: 20px;
          border: none;
          background-color: #9799d6 !important;
          padding: 2px 11px;

          .counter {
            color: #2dabe2;
          }
        }
      }
    }
  }
}
.tabs-class-reglement {
  .nav-tabs .nav-link {
    background-color: #f6f6f6 !important;
    border: none;
    color: black !important;
    font-size: 12px !important;
    font-weight: 800 !important;
  }
  .nav-tabs .nav-link.active {
    background-color: #908fb3 !important;
    border: none;
    color: #fff !important;
  }
}
.custom-input {
  width: 100% !important;
  height: 31px;
  border-radius: 17px;
  padding-top: 5px;
  background-color: #7070701c;
}
.fix-rapport-style {
  display: block !important;
}

.cutsom-modal-bootstrap-reglement .modal-dialog {
  top: 0% !important;
  height: calc(100vh - 32px) !important;
  max-width: 90% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-reglement .modal-dialog {
    top: 0%;
    max-width: 90%;
    height: 100% !important;
    margin: auto;
  }
}
.customSelectMultipleFilter.libres {
  width: 100%;
  min-height: 29px !important;
  .multiselect__placeholder {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .multiselect__tags {
    padding: 3px 32px 0px 17px;
    min-height: 21px;
    border-radius: 50px !important;
    width: 100%;
  }
  .multiselect__select {
    width: 30px;
    height: 29px;
  }
  .multiselect__spinner {
    height: 27px;
    border-radius: 0px 50px 50px 0px;
    width: 22px;
  }
  .multiselect__option {
    padding: 4px;
    min-height: 27px;
    line-height: 16px;
    font-size: 11px !important;
  }
  .multiselect__single {
    display: none;
    font-size: 9px !important;
    height: 21px;
  }
  .multiselect__input {
    font-size: 9px !important;
    height: 21px;
  }
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #8d8cb7;
    border-radius: 7px;
  }
  .showBlock {
    display: block;
    font-size: 9px;
    font-weight: 600;
    margin-bottom: 2px;
    margin-top: 2px;
  }
  .multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: '';
  }
}
.table-rapport-style .TableFraisUpdateReglementFacture {
  .item-details-style {
    margin: 2px 0px;
  }
  td,
  th {
    font-size: 8.5px;
    padding: 2px 2px !important;
    min-width: 13%;
    width: 13%;
  }
  .width-service {
    min-width: 40px;
    width: 40px;
  }
}
</style>
